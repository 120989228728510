<script setup lang="ts">
const {locale} = useI18n()

type ImageProps = {
    src: string
    alt: string
}
const frImages: ImageProps[] = [
  {src: 'fr/01-nouvelle-aquitaine.png', alt: 'Région Nouvelle-Aquitaine'},
  {src: 'fr/02-grand-belfort.png', alt: 'Mairie de Belfort'},
  {src: 'fr/03-cite-des-sciences.png', alt: 'Cité des sciences et de l’industrie'},
  {src: 'fr/04-cssm-quebec.png', alt: 'CSSM Québec'},
  {src: 'fr/05-lumni.png', alt: 'Lumni'},
  {src: 'fr/06-ile-de-france.png', alt: 'Région Île-de-France'},
  {src: 'fr/07-bibliotheque-quebec.png', alt: 'Bibliothèque de Québec'},
  {src: 'fr/08-canton-geneve.png', alt: 'Canton de Genève'},
  {src: 'fr/09-landes.png', alt: 'Conseil Général des Landes'},
  {src: 'fr/10-hauts-de-france.png', alt: 'Région Hauts-de-France'},
]

const enImages: ImageProps[] = [
  {src: 'en/01-awty-houston.png', alt: 'AWTY Houston'},
  {src: 'en/02-arizona-state-university.png', alt: 'Arizona State University'},
  {src: 'en/03-english-montreal-school-board.png', alt: 'English Montreal School Board'},
  {src: 'en/04-university-lethbridge.png', alt: 'University of Lethbridge'},
  {src: 'en/05-ottawa-public-library.png', alt: 'Ottawa Public Library'},
  {src: 'en/06-washington-international-school.png', alt: 'Washington International School'},
  {src: 'en/07-pa-cyber.png', alt: 'Pennsylvania Cyber School'},
  {src: 'en/08-lwis-cis-beirut.png', alt: 'LWIS-CiS | DT Beirut'},
  {src: 'en/09-cree-school-board.png', alt: 'CREE School Board'},
  {src: 'en/10-oakton-college.png', alt: 'Oakton College'},
]

const images = computed(() => {
  switch (locale.value) {
    case 'fr':
      return frImages
    default:
      return enImages
  }
})

const scroller = ref<HTMLElement|null>(null)
const scroll = useScroll(scroller)
</script>

<template>
  <div class="rounded-bento bg-orange text-white">
    <ShowcasePartsStoreyColumn>
      <ShowcaseTextsRevealPillHeading class="text-center" icon="handshake" :text="$t('showcase.clients.pill')" />
      <ShowcaseTextsStoreyHeading class="text-center mb-4">{{ $t('showcase.clients.title') }}</ShowcaseTextsStoreyHeading>
      <div class="relative">
        <div class="absolute pointer-events-none top-0 left-0 bottom-0 w-[20%] transition-opacity opacity-0 bg-gradient-to-r from-orange to-transparent md:hidden" :class="{'!opacity-100': !scroll.arrivedState.left}"></div>
        <div class="absolute pointer-events-none top-0 right-0 bottom-0 w-[20%] transition-opacity opacity-0 bg-gradient-to-r from-transparent to-orange md:hidden" :class="{'!opacity-100': !scroll.arrivedState.right}"></div>
        <div class="w-full flex gap-3 md:gap-0 overflow-x-auto scrollbar-hide md:flex-wrap" ref="scroller">
          <div v-for="image in images" :key="image.src" class="w-[36%] max-w-[300px] md:w-1/5 md:px-4 md:py-2 grow-0 shrink-0 text-center flex items-center justify-center">
            <img :src="`/assets/showcase/clients/${image.src}`" :alt="image.alt" class="block m-auto max-h-64" loading="lazy" />
          </div>
        </div>
      </div>
    </ShowcasePartsStoreyColumn>
  </div>
</template>