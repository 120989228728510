<script setup lang="ts">
import type {MediaCardData} from '~/types/media/MediaCardData'
import {useShowcaseStore} from "~/stores/showcase"
import {storeToRefs} from "pinia"
import {useElementSize} from "@vueuse/core"

const showcaseStore = useShowcaseStore()
const { selectedNode, selectedNodeMediaCards: mediaCards } = storeToRefs(showcaseStore)

const numCols = 5
const maxCards = 15

const displayedCards = computed(() => {
  return mediaCards.value.slice(0, maxCards)
})

const cardColumns = computed(() => {
  const columns: { card: MediaCardData, index: number, delay: number }[][] = []

  for (let i = 0; i < numCols; i++) {
    columns.push([])
  }

  displayedCards.value.forEach((card, index) => {
    const abs = index % numCols
    const ord = columns[abs].length

    const distanceToOrigin = Math.sqrt(Math.pow(abs, 2) + Math.pow(ord, 2))

    columns[abs].push({card, index, delay: distanceToOrigin * 100})
  })

  return columns
})

watch([cardColumns], () => {
  const $cards = [...document.querySelectorAll('[data-card-index]')].sort((a: Element, b: Element) => {
    return parseInt((a as HTMLDivElement).dataset.cardIndex || '0') - parseInt((b as HTMLDivElement).dataset.cardIndex || '0')
  })

  const baseStateClasses = ['opacity-0', 'translate-x-[40%]', 'translate-y-[20%]', 'rotate-3', 'scale-110']

  $cards.forEach(($card, index) => {
    const delay = parseInt(($card as HTMLDivElement).dataset.cardDelay || '0')
    $card.classList.add('transition', ...baseStateClasses)
    setTimeout(() => {
      $card.classList.remove(...baseStateClasses)
    }, delay)
  })
}, {flush: 'post'})

const el = ref<HTMLElement|null>(null)
const { width: zoomerWidth } = useElementSize(el)

const zoom = computed(() => {
  return zoomerWidth.value < 500 ? .38 : 8/12
})
</script>

<template>
  <div class="relative" ref="el">
    <LayoutsElementZoomer :zoom="zoom">
      <div class="aspect-[3/2] overflow-hidden relative">
        <div class="grid gap-2" :style="{'grid-template-columns': `repeat(${cardColumns.length}, minmax(0, 1fr))`}">
          <div class="flex flex-col gap-2" v-for="(cardColumn, columnIndex) in cardColumns" :key="columnIndex">
            <div v-for="cardItem in cardColumn" :key="cardItem.card.id" :data-card-index="cardItem.index" :data-card-delay="cardItem.delay" class="duration-500 ease-wavy">
              <ShowcasePartsIntroCard :card-data="cardItem.card" />
            </div>
          </div>
        </div>
      </div>
    </LayoutsElementZoomer>
    <div class="absolute -bottom-1 -left-1 -right-1 h-1/2 bg-gradient-to-t from-domain-dark flex items-end justify-end px-4 py-2 text-right">
      <p>{{ selectedNode?.name }}<br>
        <span class="opacity-50">{{ mediaCards.length }} {{ $t('showcase.media_wall.resources') }}</span>
      </p>
    </div>
  </div>
</template>