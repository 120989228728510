<script setup lang="ts">
import {useDomainDataStore} from "~/stores/domain"
import {useViewportBounding} from "~/composables/dom/useScroll"
import ToolFloor from "~/components/showcase/parts/ToolFloor.vue"

const {domainData} = useDomainDataStore()

const captureFloor = ref<typeof ToolFloor | null>(null)
const flipFloor = ref<typeof ToolFloor | null>(null)
const shareFloor = ref<HTMLDivElement | null>(null)

const captureBounding = useViewportBounding(captureFloor)
const flipBounding = useViewportBounding(flipFloor)
const shareBounding = useViewportBounding(shareFloor)

const mostCenterFloor = computed(() => {
  if (!captureFloor.value || !flipFloor.value || !shareFloor.value) {
    return null
  }

  return [captureBounding, flipBounding, shareBounding].sort((a, b) => {
    return a.centerDistanceToViewportCenter.value < b.centerDistanceToViewportCenter.value ? -1 : 1
  })[0]
})

const discreetOpacity = 'opacity-25'

watch(mostCenterFloor, (newFloor, previousFloor) => {
  previousFloor?.el.value?.classList.add(discreetOpacity)
  newFloor?.el.value?.classList.remove(discreetOpacity)
})

const floorClasses = ref(`transition-opacity duration-1000 ${discreetOpacity}`)
</script>

<template>
  <div class="rounded-bento overflow-hidden bg-navy text-white">
    <ShowcasePartsStoreyColumn>
      <div class="mb-6 md:mb-12">
        <ShowcaseTextsRevealPillHeading icon="person" :text="$t('showcase.tools.pill')"/>
        <ShowcaseTextsStoreyHeading class="mb-6">{{ $t('showcase.tools.title') }}</ShowcaseTextsStoreyHeading>
        <div class="max-w-prose">
          <ShowcaseTextsStoreySubHeading>{{ $t('showcase.tools.lead') }}</ShowcaseTextsStoreySubHeading>
        </div>
      </div>
      <div ref="captureFloor" :class="floorClasses">
        <ShowcasePartsToolFloor>
          <template #text>
            <ShowcaseTextsStoreySubHeading><strong>{{ $t('showcase.tools.capture.title') }}{{ $t('_global.colon') }}</strong><span v-html="t('showcase.tools.capture.lead', {lineBreak: '<br>'})"></span></ShowcaseTextsStoreySubHeading>
            <p>{{ $t('showcase.tools.capture.body') }}</p>
          </template>
          <template #media>
            <ShowcasePartsCaptureVideo />
          </template>
        </ShowcasePartsToolFloor>
      </div>
      <div ref="flipFloor" :class="floorClasses">
        <ShowcasePartsToolFloor :reverse="true">
          <template #text>
            <ShowcaseTextsStoreySubHeading><strong>{{ $t('showcase.tools.flip.title') }}{{ $t('_global.colon') }}</strong><span v-html="$t('showcase.tools.flip.lead')"></span></ShowcaseTextsStoreySubHeading>
            <p>{{ $t('showcase.tools.flip.body') }}</p>
          </template>
          <template #media>
            <ShowcasePartsFlipVideo />
          </template>
        </ShowcasePartsToolFloor>
      </div>
      <div class="md:flex gap-12 mb-8 md:mb-12" :class="floorClasses" ref="shareFloor">
        <div class="basis-1/2">
          <div class="max-w-sm mx-auto">
            <AtomsLottie path="/assets/showcase/lottie/favorite.json" class="aspect-[800/500]"/>
            <div class="mx-auto">
              <ShowcaseTextsStoreySubHeading><strong>{{ $t('showcase.tools.favorite.title') }}{{ $t('_global.colon') }}</strong><span v-html="$t('showcase.tools.favorite.lead')"></span></ShowcaseTextsStoreySubHeading>
              <p>{{ $t('showcase.tools.favorite.body') }}</p>
            </div>
          </div>
        </div>
        <div class="basis-1/2">
          <div class="max-w-sm mx-auto">
            <AtomsLottie path="/assets/showcase/lottie/share.json" class="aspect-[800/500]"/>
            <div class="mx-auto">
              <ShowcaseTextsStoreySubHeading><strong>{{ $t('showcase.tools.share.title') }}{{ $t('_global.colon') }}</strong><span v-html="$t('showcase.tools.share.lead')"></span></ShowcaseTextsStoreySubHeading>
              <p>{{ $t('showcase.tools.share.body') }}</p>
            </div>
          </div>
        </div>
      </div>
      <p class="text-center">
        <AtomsButton :to="localePath('teachers-area')" :label="$t('showcase.tools.learn_more')" hover-scheme="border" />
      </p>
    </ShowcasePartsStoreyColumn>
  </div>
</template>