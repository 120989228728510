<script setup lang="ts">
import {useDomainDataStore} from "~/stores/domain"
import {useUserStore} from "~/stores/user"

const { refsToFormData } = useFormData()
const { $i18n } = useNuxtApp()
const { geoData } = storeToRefs(useUserStore())
const domainDataStore = useDomainDataStore()

const roleOptions = [
  {label: $i18n.t('contact.roles.teacher'), value: 'teacher'},
  {label: $i18n.t('contact.roles.parent'), value: 'parent'},
  {label: $i18n.t('contact.roles.educational_consultant'), value: 'educational_consultant'},
  {label: $i18n.t('contact.roles.technical_manager'), value: 'technical_manager'},
]

const firstName = ref('')
const lastName = ref('')
const email = ref('')
const institution = ref('')
const city = ref('')
const country = ref('')
const role = ref('')
const message = ref('')

watchEffect(() => {
  if (geoData.value.city) {
    city.value = geoData.value.city
  }
  if (geoData.value.country) {
    country.value = geoData.value.country
  }
})

const fields = {
  firstName,
  lastName,
  email,
  institution,
  city,
  country,
  role,
  message,
}

const minMessageChars = 20

const disabled = computed(() => {
  if (message.value.trim().length < minMessageChars) {
    return true
  }

  return Object.values(fields).some(field => field.value.trim() === '')
})

const pending = ref<boolean>(false)
const formError = ref<string|null>(null)
const success = ref<boolean>(false)

async function onSubmit() {
  pending.value = true

  const body = refsToFormData(fields)
  body.append('context', 'showcase')

  const {error} = await useApiFetch('/contact', { method: 'post', body })
  pending.value = false

  success.value = error.value === null
  if (success.value) {
    message.value = ''
  }

  if (!error.value) {
    return
  }

  // TODO: Auto-scroll to error field (see contact page)

  switch (error.value.data.message) {
    case 'empty_field':
      formError.value = $i18n.t('contact.empty_field')
      break
    case 'invalid_email':
      formError.value = $i18n.t('contact.invalid_email')
      break
    default:
      formError.value = $i18n.t('_global.error')
      break
  }
}

watch(Object.values(fields), () => {
  formError.value = null
})
</script>

<template>
  <div class="rounded-bento bg-gold text-white" id="demo">
    <ShowcasePartsStoreyColumn>
      <ShowcaseTextsRevealPillHeading class="text-center" icon="drafts" :text="$t('showcase.contact.tell_us_everything')" />
      <ShowcaseTextsStoreyHeading class="mb-6 text-center">{{ $t('showcase.contact.request_demo') }}</ShowcaseTextsStoreyHeading>
      <div class="max-w-prose bg-white text-domain-dark p-4 md:p-6 lg:p-8 mx-auto rounded-bento">
        <form v-if="!success" :class="{'opacity-50 pointer-events-none': pending}" @submit.prevent="onSubmit">
          <div class="flex gap-5 mb-5">
            <AtomsFormTextInput class="grow" :label="$t('contact.first_name')" :required="true" v-model="firstName" />
            <AtomsFormTextInput class="grow" :label="$t('contact.last_name')" :required="true" v-model="lastName" />
          </div>
          <AtomsFormTextInput class="mb-5" :label="$t('contact.pro_email')" type="email" :required="true" v-model="email" />
          <AtomsFormTextInput class="mb-5" :label="$t('contact.institution_name')" :required="true" v-model="institution" />
          <AtomsFormChoice class="mb-5" :label="$t('contact.country')" :required="true" :options="domainDataStore.countryOptions" v-model="country" />
          <AtomsFormTextInput class="mb-5" :label="$t('contact.city')" :required="true" v-model="city" />
          <AtomsFormChoice class="mb-5" :label="$t('contact.role')" :required="true" :options="roleOptions" v-model="role" />
          <AtomsFormTextArea class="mb-5" :label="$t('contact.message')" :required="true" input-class="h-24" v-model="message">
            <template #after-input><p class="text-button-small opacity-50">{{ t('showcase.contact.min_chars', {minMessageChars}) }}</p></template>
          </AtomsFormTextArea>
          <AtomsFormError :text="formError" />
          <div class="text-right">
            <AtomsButton :label="$t('contact.send')" dark-color="domain" tag="button" :disabled="disabled" />
          </div>
        </form>
        <div v-else>
          <!-- TODO: Better wording and layout?-->
          <AtomsFormSuccess :text="$t('contact.send_success')" class="!mb-0" />
        </div>
      </div>
    </ShowcasePartsStoreyColumn>
  </div>
</template>