<script setup lang="ts">
const openUnderline = '<span class="underline underline-offset-4 decoration-domain">'
const closeUnderline = '</span>'
</script>

<template>
  <div class="rounded-bento bg-domain-dark text-white p-4 md:p-10 lg:p-12">
    <div class="mx-auto max-w-prose text-xl">
      <p class="mb-12 text-xl">
        <ShowcasePartsRevealPill icon="campaign" :text="$t('showcase.manifesto.pill')" />
      </p>
      <div ref="page" class="bg-white px-4 py-8 md:px-8 md:py-12 lg:px-12 lg:py-16 text-domain-dark rounded-bento">
        <p class="mb-12 text-3xl" v-html="t('showcase.manifesto.body.p1', {openUnderline, closeUnderline})" />
        <p class="mb-8 text-xl">{{ t('showcase.manifesto.body.p2') }}</p>
        <p class="mb-8 text-xl">{{ t('showcase.manifesto.body.p3') }}</p>
        <p class="mb-8 text-xl">{{ t('showcase.manifesto.body.p4') }}</p>
        <p class="text-3xl" v-html="t('showcase.manifesto.body.p5', {openUnderline, closeUnderline, lineBreak: '<br>'})" />
      </div>
    </div>
  </div>
</template>