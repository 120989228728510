import type {Ref} from 'vue'
import {computed} from "@vue/reactivity"
import {useElementBounding, useWindowSize} from "@vueuse/core"

export const useViewportBounding = ($el: Ref<HTMLElement | null>) => {
  const windowSize = useWindowSize()

  const bounding = useElementBounding($el)

  const isUnderViewport = computed(() => {
    return bounding.top.value > windowSize.height.value
  })

  const isOverViewport = computed(() => {
    return bounding.bottom.value < 0
  })

  const isLessHighThanViewport = computed(() => {
    return bounding.height.value < windowSize.height.value
  })

  const isFullyVisible = computed(() => {
    return isLessHighThanViewport.value && bounding.top.value > 0 && bounding.bottom.value <= windowSize.height.value
  })

  const centerDistanceToViewportCenter = computed(() => {
    return Math.abs(bounding.top.value + bounding.height.value / 2 - windowSize.height.value / 2)
  })

  return {
    el: $el,

    bounding,
    windowSize,

    isUnderViewport,
    isOverViewport,
    isLessHighThanViewport,
    isFullyVisible,

    centerDistanceToViewportCenter,
  }
}

export const useHeightScroll = ($el: Ref<HTMLElement | null>) => {
  const viewportBounding= useViewportBounding($el)

  const scrollDistance = computed(() => {
    return viewportBounding.bounding.height.value + viewportBounding.windowSize.height.value
  })

  const scrolledDistance = computed(() => {
    // Fake value to prevent NaN issues
    return (viewportBounding.windowSize.height.value === Infinity ? 1000 : viewportBounding.windowSize.height.value) - viewportBounding.bounding.top.value
  })

  const scrolledRatio = computed(() => {
    return Math.max(0, Math.min(1, scrolledDistance.value / scrollDistance.value))
  })

  return {
    scrollDistance,
    scrolledDistance,
    scrolledRatio,
  }
}
