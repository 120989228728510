import {defineStore} from "pinia"
import type {TreeNodeData} from '~/types/TreeNodeData'
import type {MediaCardData} from '~/types/media/MediaCardData'
import {useNodeStore} from "~/stores/node"
import type {DomainData} from '~/types/DomainData'
import {useDomainDataStore} from "~/stores/domain"

export const useShowcaseStore = defineStore('showcase', () => {

    const nodeStore = useNodeStore()
    const domainStore = useDomainDataStore()

    const domain = ref<string|null>(null)

    const rootNodes = computed(() => {
        if (!domain.value) {
            return []
        }

        return domainStore.domainData.showcase.browser[domain.value]?.tree.children?.filter(node => node.children?.length) || []
    })

    const openNodePerDomain = ref<{[key: string]: TreeNodeData}>({})
    const selectedNodePerDomain = ref<{[key: string]: TreeNodeData}>({})

    const openNode = computed({
        get: () => {
            if (!domain.value) {
                return null
            }

            return openNodePerDomain.value[domain.value] || (rootNodes.value.length ? rootNodes.value[0] : null)
        },
        set: (v) => {
            if (!domain.value || !v) {
                return
            }

            openNodePerDomain.value[domain.value] = v
        },
    })

    const selectedNode = computed({
        get: () => {
            if (!domain.value) {
                return null
            }

            return selectedNodePerDomain.value[domain.value] || openNode.value
        },
        set: (v) => {
            if (!domain.value || !v) {
                return
            }

            selectedNodePerDomain.value[domain.value] = v
        },
    })

    function initWithDomainData(t: DomainData) {
        domain.value = t.domain
    }

    const selectedNodeMediaCards = ref<MediaCardData[]>([])

    watch([selectedNode], async () => {
        if (!selectedNode.value) {
            return
        }

        const nodeData = await nodeStore.getNode(selectedNode.value.id, false, {server: false})
        selectedNodeMediaCards.value = nodeData.medias || []
    })

    return {
        initWithDomainData,

        domain,
        rootNodes,
        openNode,
        selectedNode,
        selectedNodeMediaCards,
    }

})