<script setup lang="ts">
import {useDomainDataStore} from "~/stores/domain"
import {useWindowSize} from "@vueuse/core"
import {useHeightScroll} from "~/composables/dom/useScroll"
import {useElementVisibility} from "@vueuse/core/index"
import {SHOWCASE_VIDEO_SRC_SET} from "~/types/bus-event-names"

const numCols = 12
const numRows = 5
const el = ref<HTMLElement|null>(null)
const nodePreview = ref<HTMLElement|null>(null)
const { width: windowWidth, height: windowHeight } = useWindowSize()

const shuffleColumnsState = useState('showcaseMediaWallColumns', () => {
  const shuffledColumns = []
  const shuffledCards = [...useDomainDataStore().domainData.showcase.wall.media].sort(() => Math.random() - 0.5)

  let cardCounter = 0

  for (let i = 0; i < numCols; i++) {
    const cards = []

    for (let j = 0; j < numRows; j++) {
      cards.push(shuffledCards[cardCounter % shuffledCards.length])
      cardCounter++
    }

    shuffledColumns.push({
      cards,
      speed: -(25 + Math.random() * 50)
    })
  }

  return shuffledColumns
})

const heightScroll = useHeightScroll(el)

const numDisplayedColumns = computed(() => {
  return Math.min(numCols, Math.round(windowWidth.value / 100))
})

const maxDisplayedRows = computed(() => {
  return Math.min(numRows, Math.round(windowHeight.value / 200))
})

const cardStyle = computed(() => {
  return {
    opacity: 1 - heightScroll.scrolledRatio.value
  }
})

function scrollToPreview() {
  if (!nodePreview.value) {
    return
  }

  nodePreview.value.scrollIntoView({
    behavior: 'smooth',
    block: 'center',
  })
}

const mediaWallNeeded = ref(false)
const elementIsVisible = useElementVisibility(el)

const unwatch = watch(elementIsVisible, (isVisible) => {
  if (isVisible) {
    mediaWallNeeded.value = true
    unwatch()
  }
})

useBusEvent(SHOWCASE_VIDEO_SRC_SET, () => {
  mediaWallNeeded.value = true
})
</script>

<template>
  <div class="rounded-bento bg-domain-dark text-white relative overflow-hidden" ref="el">
    <div class="absolute top-0 w-full h-[20%] z-20 bg-gradient-to-b from-domain-dark"></div>
    <LayoutsElementZoomer :zoom.number=".5" class="mb-[-12%] relative z-10 pointer-events-none" v-if="mediaWallNeeded">
      <div class="grid gap-2" :style="{'grid-template-columns': `repeat(${numDisplayedColumns}, minmax(0, 1fr))`}">
        <div v-for="(column, columnIndex) in shuffleColumnsState"
             :key="columnIndex"
             class="flex flex-col gap-2"
             :style="{
                 transform: `translateY(${column.speed * heightScroll.scrolledRatio.value}%)`,
                 display: numDisplayedColumns < columnIndex + 1 ? 'none' : null
               }">
          <div v-for="(cardData, cardIndex) in column.cards" :key="cardIndex" class="bg-domain-dark rounded-lg">
            <ShowcasePartsIntroCard v-if="cardIndex <= maxDisplayedRows" :card-data="cardData" class="!transition-none" :style="cardStyle" />
          </div>
        </div>
      </div>
    </LayoutsElementZoomer>
    <ShowcasePartsStoreyColumn :no-bottom-border="true">
      <div class="mb-6 md:mb-12">
        <ShowcaseTextsRevealPillHeading icon="source" :text="$t('showcase.media_wall.pill')" />
        <ShowcaseTextsStoreyHeading>{{ $t('showcase.media_wall.title') }}</ShowcaseTextsStoreyHeading>
      </div>
      <div class="lg:flex gap-10">
        <div class="lg:w-4/12 mb-6">
          <ShowcasePartsDomainToggle class="mb-4" />
          <ShowcasePartsTreeBrowser @node-selected="scrollToPreview" />
        </div>
        <div class="lg:w-8/12 mb-6 lg:mb-0" ref="nodePreview">
          <ShowcasePartsTreeNodePreview />
        </div>
      </div>
    </ShowcasePartsStoreyColumn>
  </div>
</template>