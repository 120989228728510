<script setup lang="ts">
import {useElementSize} from "@vueuse/core"

type Props = {
  scale: number
}

const props = withDefaults(defineProps<Props>(), {
  scale: .5,
})

const transformer = ref<HTMLElement|null>(null)
const { width, height } = useElementSize(transformer)

// const scaledWidth = computed(() => width.value * props.scale)
const scaledHeight = computed(() => height.value * props.scale)
</script>

<template>
  <div ref="el" :style="{/*width: scaledWidth + 'px',*/ height: scaledHeight + 'px'}">
    <div ref="transformer" class="origin-top-left" :style="{transform: `scale(${scale})`}">
      <slot />
    </div>
  </div>
</template>