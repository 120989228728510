<script setup lang="ts">
import {refDebounced} from "@vueuse/core"
import Video from "~/components/showcase/storeys/Video.vue"
import {useViewportBounding} from "~/composables/dom/useScroll"

const showLogo = ref<boolean>(false)
const debouncedShowLogo = refDebounced(showLogo, 250)

function onLogoVisibilityChange(isVisible: boolean) {
    showLogo.value = !isVisible
}

function scrollToTop() {
  window.scrollTo({top: 0, behavior: 'smooth'})
}

const videoStorey = ref<typeof Video>()
const videoStoreyBounding = useViewportBounding(videoStorey)
</script>

<template>
  <header class="flex flex-col-reverse md:flex-row flex-wrap justify-end gap-2 p-2 md:p-4 lg:px-8 lg:py-4 bg-domain-dark md:sticky top-0 z-50 items-center">
    <Transition enter-from-class="opacity-0" leave-to-class="opacity-0" enter-active-class="transition" leave-active-class="transition">
      <a v-if="debouncedShowLogo" class="cursor-pointer hidden md:block w-[142px] lg:w-[232px]" @click.prevent="scrollToTop">
        <img src="/assets/images/logo-w.svg" alt="EduMedia" width="484" height="100" class="max-w-full">
      </a>
    </Transition>
    <span class="m-auto"></span>
    <div class="w-full md:w-auto flex gap-2">
      <AtomsHeaderLocalePicker size="fluid" hover-scheme="default" class="mr-auto md:mr-0" />
      <AtomsButton :label="$t('login.title')" :to="localePath('login')" size="fluid" />
    </div>
    <ShowcasePartsDemoPricingButton class="w-full md:hidden" />
    <div class="hidden md:block overflow-hidden -ml-2">
      <LayoutsSlideToggler direction="horizontal" :open="videoStoreyBounding.isOverViewport.value">
        <div class="ml-2 whitespace-nowrap">
          <ShowcasePartsDemoPricingButton class="w-full md:w-auto" />
        </div>
      </LayoutsSlideToggler>
    </div>
    <MoleculesDialogDomainPicker />
    <MoleculesDialogLocalePicker />
  </header>
  <div class="bg-domain-dark flex flex-col pt-0 p-2 md:p-4 md:pt-0 gap-2 md:gap-4">
    <ShowcaseStoreysIntro @logo-visibility-change="onLogoVisibilityChange" />
    <ShowcaseStoreysVideo ref="videoStorey" />
    <ShowcaseStoreysMediaWall />
    <ShowcaseStoreysInteractive />
    <ShowcaseStoreysTools />
    <ShowcaseStoreysManifesto />
    <ShowcaseStoreysClients />
    <ShowcaseStoreysContact />
    <ShowcaseStoreysFooter />
  </div>
</template>
