<script setup lang="ts">
import {useElementVisibility} from "@vueuse/core"

const poster = ref<HTMLElement|null>(null)
const posterVisibility = useElementVisibility(poster)
</script>

<template>
  <div class="rounded-bento overflow-hidden bg-turquoise text-white">
    <ShowcasePartsStoreyColumn :no-bottom-border="true">
      <div class="mb-6 md:mb-12">
        <ShowcaseTextsRevealPillHeading icon="visibility" :text="$t('showcase.interactive.pill')" />
        <ShowcaseTextsStoreyHeading>{{ $t('showcase.interactive.title') }}</ShowcaseTextsStoreyHeading>
      </div>
      <div class="md:flex items-center gap-10">
        <div class="md:w-7/12 self-end transition delay-200 duration-1000 opacity-0 translate-y-1/4 scale-95 origin-bottom mb-6 md:mb-0"
             :class="posterVisibility ? ['!opacity-100', '!translate-y-0', '!scale-100', 'rotate-0'] : ['rotate-3']"
             ref="poster">
          <AtomsLottie class="aspect-[800/500]" path="/assets/showcase/lottie/interactive.json" />
        </div>
        <div class="md:w-5/12 md:mb-6">
          <ShowcaseTextsStoreySubHeading>{{ $t('showcase.interactive.lead') }}</ShowcaseTextsStoreySubHeading>
          <p>{{ $t('showcase.interactive.body') }}</p>
        </div>
      </div>
    </ShowcasePartsStoreyColumn>
  </div>
</template>