<script setup lang="ts">
import {useHeightScroll} from "~/composables/dom/useScroll"
import {useEventListener} from "@vueuse/core"
import {useElementVisibility} from "@vueuse/core/index"
import {SHOWCASE_VIDEO_SRC_SET} from "~/types/bus-event-names"
import {useDomainDataStore} from "~/stores/domain";
import type LazyVideo from '~/components/atoms/LazyVideo.vue'

const videoWrapper = ref<HTMLDivElement|null>(null)
const videoCompo = ref<typeof LazyVideo|null>(null)
const circle = ref<SVGCircleElement|null>(null)

const heightScroll = useHeightScroll(videoWrapper)
const {domainData} = useDomainDataStore()

const emitter = useNuxtApp().$mitt

const fullVideoRatio = computed(() => {
  return Math.min(1, heightScroll.scrolledRatio.value / .45)
})

function fromTo(from: number, to: number) {
  return from + (to - from) * fullVideoRatio.value
}

function easedFromTo(from: number, to: number) {
  return from + (to - from) * ease(fullVideoRatio.value)
}

function ease(x: number) {
  return Math.sin((x * Math.PI) / 2)
}

const videoWrapperStyle = computed(() => {
  return {
    transform: `scale(${(easedFromTo(.6, 1))}) perspective(100px) rotateX(${easedFromTo(4, 0)}deg) translateY(${easedFromTo(40, 0)}%)`,
    opacity: fromTo(60, 100) + '%'
  }
})

const duration = ref(0)
const currentTime = ref(0)
const playing = ref(false)

const video = computed(() => {
  return videoCompo.value?.videoElement
})

onMounted(() => {
  // The video is autoplay, so we're confident with the initial state
  playing.value = true

  watch(playing, (val) => {
    val ? video.value?.play() : video.value?.pause()
  })

  const { pause: pauseVideoDurationInterval } = useInterval(100, {
    controls: true,
    callback: () => {
      if (video.value?.duration) {
        duration.value = video.value.duration
        pauseVideoDurationInterval()
      }
    },
  })

  watchEffect(() => {
    if (!video.value) {
      return
    }

    useEventListener(video, 'timeupdate', () => {
      currentTime.value = video.value!.currentTime
    })
    useEventListener(video, 'play', () => {
      playing.value = true
    })
    useEventListener(video, 'pause', () => {
      playing.value = false
    })
  })
})

const playCircleLength = computed(() => {
  return circle.value?.getTotalLength() || 0
})

function togglePlaying() {
  playing.value = !playing.value
}

const videoIsVisible = useElementVisibility(videoWrapper)

const unwatch = watch(videoIsVisible, (isVisible) => {
  if (isVisible) {
    emitter.emit(SHOWCASE_VIDEO_SRC_SET)
    unwatch()
  }
})
</script>

<template>
  <div class="rounded-bento overflow-hidden text-white" ref="el">
    <ShowcasePartsStoreyColumn :no-bottom-border="true">
      <div class="mb-6 md:mb-12">
        <ShowcaseTextsRevealPillHeading icon="emoji_objects" :text="$t('showcase.video.pill')" />
        <ShowcaseTextsStoreyHeading>{{ $t('showcase.video.title') }}</ShowcaseTextsStoreyHeading>
      </div>
      <div class="max-w-prose mb-8">
        <ShowcaseTextsStoreySubHeading>{{ $t('showcase.video.lead') }}</ShowcaseTextsStoreySubHeading>
      </div>
      <div ref="videoWrapper" class="-mx-6 md:-mx-12 mb-6">
        <div class="rounded-[1rem] overflow-hidden group relative" :style="videoWrapperStyle">
          <AtomsLazyVideo ref="videoCompo" class="block aspect-[1900/830] w-full" poster="/assets/showcase/video/main-poster.png" :sources="[
              {src: `/assets/showcase/video/main-${domainData.domain}.webm`, type: 'video/webm'},
              {src: `/assets/showcase/video/main-${domainData.domain}.mp4`, type: 'video/mp4'},
              ]" @click="togglePlaying" />
          <div class="absolute w-full bottom-0 flex p-4 items-end">
            <button class="relative rounded-full w-12 h-12 bg-domain-dark/75 flex items-center justify-center transition opacity-0 group-hover:opacity-100" :class="playing ? '' : 'opacity-100'" @click="togglePlaying">
              <span class="material-icons-outlined">{{ playing ? 'pause' : 'play_arrow' }}</span>
              <svg class="absolute w-full h-full -rotate-90">
                <circle ref="circle" cx="50%" cy="50%" r="49%" stroke="white" stroke-width="2" fill="none" :style="{strokeDasharray: playCircleLength, strokeDashoffset: playCircleLength - playCircleLength * currentTime / duration}" />
              </svg>
            </button>
            <ShowcasePartsDemoPricingButton class="ml-auto" />
          </div>
        </div>
      </div>
    </ShowcasePartsStoreyColumn>
  </div>
</template>
