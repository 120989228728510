<script setup lang="ts">
import {useElementSize, useElementVisibility, useWindowScroll, useWindowSize} from "@vueuse/core"
import {useDomainDataStore} from "~/stores/domain"

const el = ref<HTMLElement | null>(null)
const logotype = ref<HTMLElement | null>(null)

const showcaseCardData = useDomainDataStore().domainData.showcase.intro.media
const logotypeVisibility = useElementVisibility(logotype)

const { width, height } = useElementSize(el)
const { y } = useWindowScroll()
const { width: windowWidth } = useWindowSize()

function ease(x: number): number {
  return x
}

const scrollPercent = computed(() => {
  if (!y.value || !height.value) {
    return 0
  }

  const val = y.value / height.value

  return ease(Math.min(Math.max(val, 0), 1))
})

function percentStyle(prefix: string | null = null, from: number, to: number, suffix: string | null = null) {
  return (prefix || '') + computed(() => {
    return from + (to - from) * scrollPercent.value
  }).value + (suffix || '')
}

const hasWindowWidth = ref(false)
onMounted(() => {
  hasWindowWidth.value = true
})

const scaleRatio = computed(() => {
  if (!hasWindowWidth.value) {
    return 1
  }

  return Math.min(1, windowWidth.value / 800)
})

function transformStyle(transform: any) {
  const ratio = scaleRatio.value
  return percentStyle('scale(', transform.scale.from * ratio, transform.scale.to * ratio, ') ')
      + percentStyle('rotate(', transform.rotate.from, transform.rotate.to, 'deg)')
}

const emit = defineEmits<{
  logoVisibilityChange: [visibility: boolean]
}>()

watchEffect(() => {
  emit('logoVisibilityChange', logotypeVisibility.value)
})

type CardStyle = {
  left?: { from: number, to: number },
  right?: { from: number, to: number },
  top: { from: number, to: number },
  transform: {
    scale: { from: number, to: number },
    rotate: { from: number, to: number }
  }
}

const cardStyles: CardStyle[] = [
  {
    left: { from: -6, to: 45 },
    top: { from: 0, to: 100 },
    transform: {
      scale: { from: .8, to: .5 },
      rotate: { from: -20, to: -5 }
    }
  },
  {
    left: { from: 20, to: 38 },
    top: { from: 46, to: 100 },
    transform: {
      scale: { from: .7, to: .5 },
      rotate: { from: -8, to: 4 }
    }
  },
  {
    left: { from: 10, to: 25 },
    top: { from: 24, to: 100 },
    transform: {
      scale: { from: 1, to: .5 },
      rotate: { from: -16, to: -8 }
    }
  },
  {
    right: { from: -4, to: 30 },
    top: { from: 0, to: 100 },
    transform: {
      scale: { from: .8, to: .5 },
      rotate: { from: 20, to: 10 }
    }
  },
  {
    right: { from: 8, to: 30 },
    top: { from: 28, to: 100 },
    transform: {
      scale: { from: .9, to: .5 },
      rotate: { from: 16, to: 10 }
    }
  },
  {
    right: { from: 28, to: 38 },
    top: { from: 40, to: 100 },
    transform: {
      scale: { from: .7, to: .5 },
      rotate: { from: -4, to: 6 }
    }
  },
  {
    left: { from: 45, to: 50 },
    top: { from: 60, to: 100 },
    transform: {
      scale: { from: .8, to: .5 },
      rotate: { from: 10, to: 6 }
    }
  },
  {
    right: { from: 16, to: 30 },
    top: { from: 40, to: 100 },
    transform: {
      scale: { from: .95, to: .5 },
      rotate: { from: 12, to: 4 }
    }
  },
  {
    left: { from: 30, to: 40 },
    top: { from: 62, to: 100 },
    transform: {
      scale: { from: .8, to: .5 },
      rotate: { from: -6, to: 2 }
    }
  },
]
</script>

<template>
  <div class="rounded-bento bg-domain-dark text-white overflow-hidden" ref="el" :style="getDomainGradientStyle(useDomainDataStore().isPrimary)">
    <div class="min-h-[80vh] relative">
      <div class="absolute z-0 w-full h-full pointer-events-none">
        <template v-for="(cardStyle, index) in cardStyles">
          <ShowcasePartsIntroCard v-if="showcaseCardData[index]"
                                  :thumbnail-eager-loading="true"
                                  :key="index"
                                  :card-data="showcaseCardData[index]"
                                  :style="{
                             left: cardStyle.left ? percentStyle(null, cardStyle.left.from, cardStyle.left.to, '%') : null,
                             right: cardStyle.right ? percentStyle(null, cardStyle.right.from, cardStyle.right.to, '%') : null,
                             top: percentStyle(null, cardStyle.top.from, cardStyle.top.to, '%'),
                             transform: transformStyle(cardStyle.transform),
                           }"
                                  class="absolute scale-50 w-[225px] rounded-[12px] bg-domain-dark border-domain-dark border-8 transition-opacity"
                                  :class="[cardStyle.left ? 'origin-left' : 'origin-right', hasWindowWidth ? 'opacity-100' : 'opacity-0']" />
        </template>
      </div>
      <div class="relative z-10 p-4 md:p-6 text-center bg-gradient-to-b from-15% from-domain-dark to-transparent md:bg-none">
        <h1 class="text-5xl md:text-6xl lg:text-7xl font-bold mb-1" ref="logotype">
          <img src="/assets/images/logo-w-e.svg" alt="EduMedia" width="100" height="100" class="block mx-auto max-w-[5rem] md:max-w-[6rem] mb-4">
          EduMedia
        </h1>
        <h2 class="lg:text-2xl mb-6 opacity-50">{{ $t('company.tagline') }}</h2>
        <div class="block mx-auto max-w-[22rem]">
          <ShowcasePartsDomainToggle class="bg-domain-dark" behavior="relocate" />
        </div>
      </div>
    </div>
  </div>
</template>