<script setup lang="ts">
import type {TreeNodeData} from '~/types/TreeNodeData'
import {useShowcaseStore} from "~/stores/showcase"
import {storeToRefs} from "pinia"

const showcaseStore = useShowcaseStore()
const { openNode, selectedNode, rootNodes } = storeToRefs(showcaseStore)

function onRootNodeClick(node: TreeNodeData) {
  openNode.value = node
  selectedNode.value = node
  emit('nodeSelected', selectedNode.value)
}

function onChildNodeClick(node: TreeNodeData) {
  selectedNode.value = node
  emit('nodeSelected', selectedNode.value)
}

const emit = defineEmits<{
  nodeSelected: [node: TreeNodeData]
}>()
</script>

<template>
  <div>
    <div class="py-2" v-for="node in rootNodes" :key="node.id">
      <button class="flex w-full text-left group" @click="onRootNodeClick(node)">
        <span class="material-icons-outlined mr-1 text-domain">label</span>
        <span class="underline decoration-2 decoration-transparent transition-colors underline-offset-4 group-hover:decoration-domain/50"
              :class="{'!decoration-domain': selectedNode === node}">{{ node.name }}</span>
      </button>
      <template v-if="node.children">
        <LayoutsSlideToggler :open="openNode === node">
          <div class="border-l-2 border-domain m-2 pl-4">
            <button class="block w-full text-left py-1 underline decoration-2 decoration-transparent transition-colors underline-offset-4 hover:decoration-domain/50"
                    v-for="child in node.children"
                    :class="{'!decoration-domain': selectedNode === child}"
                    :key="child.id"
                    @click="onChildNodeClick(child)">{{ child.name }}</button>
          </div>
        </LayoutsSlideToggler>
      </template>
    </div>
  </div>
</template>