<script setup lang="ts">
import {useMediaListStore} from "~/stores/media-list"
import {useUserStore} from "~/stores/user"
import Showcase from "~/components/showcase/Showcase.vue"

const { main: mainSize } = useMediaCardSize()
const { $i18n } = useNuxtApp()
const mediaListStore = useMediaListStore()

definePageMeta({
  publicAccess: true,
  middleware: ['index-page'],
})

useHead({
  meta: [{name: 'description', content: $i18n.t('meta.description')}],
})

</script>
<template>
  <template v-if="useUserStore().isAuthenticated">
    <AtomsMediaCard v-for="media in mediaListStore.listedMedias" :key="media.id" :card-data="media" :class="mainSize" context="generic" />
  </template>
  <Showcase v-else />
</template>